import {Component, Input, OnInit} from '@angular/core';
import Cart from '../../models/Cart';
import Product from '../../models/Product';
import cartPouchService from '../../services/CartPouchService';

@Component({
  selector: 'CardProduct',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss']
})
export class CardProductComponent implements OnInit {
  @Input() public product: Product;

  constructor() {
  }
  public getImage(): string {
    return  'background-image: url(\'\')';
  }

  ngOnInit() {
  }

  addProduct(product) {
    console.log('ADD PRODUCT');
    cartPouchService.addProduct(product);
  }


}
