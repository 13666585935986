import {Component, Input, OnInit} from '@angular/core';
import cartPouchService from '../../services/CartPouchService';
import {$e} from 'codelyzer/angular/styles/chars';

@Component({
  selector: 'CardCartProduct',
  templateUrl: './card-cart-product.component.html',
  styleUrls: ['./card-cart-product.component.scss']
})
export class CardCartProductComponent implements OnInit {
  @Input() public qtd;
  @Input() public group;
  @Input() public name;
  @Input() public priceTotal;
  date = Date.now();

  ngOnInit() {
  }

  changeQuantity = ($event) => {
    cartPouchService.changeQuantityProduct(this.group, parseInt($event.target.value));
    console.log($event, this.group);
  };
}
