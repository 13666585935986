import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ComputerScreen',
  templateUrl: './computer.screen.html',
  styleUrls: ['./computer.screen.scss']
})
export class ComputerScreen implements OnInit {

  constructor() { }

  ngOnInit() {
  }
s
}
