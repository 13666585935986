import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'SalesScreen',
  templateUrl: './sales.screen.html',
  styleUrls: ['./sales.screen.scss']
})
export class SalesScreen implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
