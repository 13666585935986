import Product from '../models/Product';
import couponService from './CouponService';
import Coupon from '../models/Coupon';


export class CartPouchService {
  public static instance = null;

  public static get() {
    if (CartPouchService.instance === null) {
      CartPouchService.instance = new CartPouchService();
    }
    return CartPouchService.instance;
  }

  public getDB() {
    const products = localStorage.getItem('products');
    return products == null ? [] : JSON.parse(products);
  }

  public getProducts() {
    return this.getDB();
  }

  changeQuantityProduct(group, quantity: number) {
    const products = this.getProducts();
    let product = null;
    const newProducts = products.filter(p => {
      if (p.group === group) {
        product = p;
        return false;
      }
      return true;
    });
    for (let i = 0; i < quantity; i++) {
      newProducts.push(product);
    }
    console.log(newProducts);
    localStorage.setItem('products', JSON.stringify(newProducts));
  }

  addProduct(product: Product) {
    let db = this.getDB();
    db.push(product);
    localStorage.setItem('products', JSON.stringify(db));
  }

  getTotalProducts() {
    const myCoupons: Array<Coupon> = couponService.getMyCoupons();
    const products: Array<Product> = this.getProducts();

    let total = 0;
    // for (const product of products) {
    //   let price = product.price;
    //   for (const coupon of myCoupons) {
    //     console.log(coupon,myCoupons)
    //     if (coupon.productGroup === product.group) {
    //       if (coupon.percentage) {
    //         price = price - (price * (coupon.value / 100));
    //       } else {
    //         price = price - coupon.value;
    //       }
    //     }
    //   }
    //
    //   total += price;
    // }
    return total;
  }
}

const cartPouchService: CartPouchService = CartPouchService.get();
export default cartPouchService;
