import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'Icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input() size: number;
  @Input() name: string;
  @Input() bg: string;
  @Input() class: string;

  constructor() {
  }

  getBG() {
    switch (this.bg) {
      case 'danger':
        return '#E63C44';
      case 'primary':
        return '#00AFEF';
      case 'success':
        return '#75C43D';
      case 'warning':
        return '#FFCC29';
      default:
        return this.bg
    }
  }

  ngOnInit() {
  }

}
