import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'HomeScreen',
  templateUrl: './home.screen.html',
  styleUrls: ['./home.screen.scss']
})
export class HomeScreen implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
