import {Component, Input, OnInit} from '@angular/core';
import cartPouchService from '../../services/CartPouchService';
import Product from '../../models/Product';
import couponService from '../../services/CouponService';


@Component({
  selector: 'CartScreen',
  templateUrl: './cart.screen.html',
  styleUrls: ['./cart.screen.scss']
})
export class CartScreen implements OnInit {
  products = this.getProducts();
  date = Date.now();
  @Input()
  public coupon;
  public errorCoupon = null;

  constructor() {

  }

  getCoupons() {
    return couponService.getMyCoupons();
  }

  validateCoupon() {
    const coupon = couponService.validateCoupon(this.coupon);
    if (typeof coupon === 'string') {
      this.errorCoupon = coupon;
      return;
    }
    couponService.addCoupon(coupon);
  }

  deleteCoupon(coupon) {
    couponService.deleteCoupon(coupon.id);
  }

  onChangeCoupon = ($event) => {
    console.log($event);
    this.coupon = $event.target.value;
  };

  onChange() {
    alert('s');
  }

  public getProducts() {
    const products: Array<Product> = cartPouchService.getProducts();
    const retorno = [];
    // for (const product of products) {
    //   if (product.group !== null) {
    //     const key = product.group;
    //     if (retorno[key] === undefined) {
    //       retorno[key] = {qtd: 0, priceTotal: 0, products: [], group: null};
    //     }
    //     retorno[key].qtd += 1;
    //     retorno[key].priceTotal += product.price;
    //     retorno[key].group = product.group;
    //     retorno[key].name = product.name;
    //
    //   }
    //
    // }
    return retorno;
  }

  getTotal() {
    return cartPouchService.getTotalProducts();
  }

  ngOnInit() {
  }

}
