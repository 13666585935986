import {Component, OnInit} from '@angular/core';
import Cart from '../../models/Cart';
import cartPouchService from '../../services/CartPouchService';
import Product from '../../models/Product';

@Component({
  selector: 'Navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  getTotal() {
    const products: Array<Product> = cartPouchService.getProducts();
    let total = 0;
    for (const product of products) {
    //  total += product.price;
    }
    return total;
  }


  constructor() {
  }

  ngOnInit() {
  }

}
