import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'AboutScreen',
  templateUrl: './about.screen.html',
  styleUrls: ['./about.screen.scss']
})
export class AboutScreen implements OnInit {
  public cards = [
    {
      img: '../../../assets/imgs/about/img1.png',
      title: 'ROBOTICS COURSE',
      items: [
        'FREE ROBOTICS KITS',
        'LEARN TO BUILD YOUR OWN ROBOT',
        'PLAY AND LEARN AT THE SAME TIME'
      ]
    },
    {
      img: '../../../assets/imgs/about/img1.png',
      title: 'ROBOT PROGRAMMING',
      items: [
        '1 COMPUTER PER STUDENT',
        'LEARN HOW TO CODE',
        'FUN WAY LEARN COMPUTER PROGRAMMING'
      ]
    },
    {
      img: '../../../assets/imgs/about/img1.png',
      title: 'Build robots from scratch',
      content:'Learn how to build complete functional robots with different types of electronic parts!'
    },
    {
      img: '../../../assets/imgs/about/img1.png',
      title: 'Learn how to program robots',
      content:'Learn program basics with Sketch, mBlock and more'
    },
    {
      img: '../../../assets/imgs/about/img1.png',
      title: 'Awaken the genius in your child',
      content:'Introduction to a new modern profession, that is growing continuously all around the world'
    },
    {
      img: '../../../assets/imgs/about/img1.png',
      title: 'Learn with fun comics',
      content:'Learn from fun, creatively illustrated comics and kits'
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
