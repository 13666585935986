import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'InputComponent',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  input: any;
  @Input() public label: string;

  @Input() public error: string = null;
  ngOnInit() {
  }


}
