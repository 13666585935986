import Product from '../models/Product';
import Coupon from '../models/Coupon';


export class CouponService {
  public static instance = null;
  key = 'coupons_validate';

  public static get(): CouponService {
    if (CouponService.instance === null) {
      CouponService.instance = new CouponService();
    }
    return CouponService.instance;
  }

  getMyCoupons() {
    const coupons = localStorage.getItem(this.key);
    return coupons === null ? [] : JSON.parse(coupons);
  }

  addCoupon(coupon: Coupon) {
    const coupons = this.getMyCoupons();
    coupons.push(coupon);
    localStorage.setItem(this.key, JSON.stringify(coupons));
  }

  getCouponsService() {
    return JSON.parse(localStorage.getItem('coupons'));
  }

  validateCoupon(code: string) {
    const coupons: Array<Coupon> = this.getCouponsService();
    const myCoupons: Array<Coupon> = this.getMyCoupons();
    for (const myCoupon of myCoupons) {
      if (myCoupon.code === code) {
        return 'Coupon já existe';
      }
    }

    for (const coupon of coupons) {
      console.log(code);
      if (coupon.code === code) {
        return coupon;
      }
    }
    return 'Cupom inválido!';
  }


  deleteCoupon(id) {
    const coupons = this.getMyCoupons();
    const newCoupons = coupons.filter(c => !(c.id === id));
    localStorage.setItem(this.key, JSON.stringify(newCoupons));
  }


}

const couponService: CouponService = CouponService.get();
export default couponService;
