import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'CardAbout',
  templateUrl: './card-about.component.html',
  styleUrls: ['./card-about.component.scss']
})
export class CardAboutComponent implements OnInit {

  @Input() item;

  constructor() { }
  ngOnInit() {
  }

}
