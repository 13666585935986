import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {AboutScreen} from './screens/about/about.screen';
import {CardAboutComponent} from './components/card-about/card-about.component';
import {HomeScreen} from './screens/home/home.screen';
import {IconComponent} from './components/icon/icon.component';
import {ContactScreen} from './screens/contact/contact.screen';
import {RouterModule, Routes} from '@angular/router';
import {FooterComponent} from './components/footer/footer.component';
import {CardProductComponent} from './components/card-product/card-product.component';
import {ProductsScreen} from './screens/products/products.screen';
import {FormsModule} from '@angular/forms';
import {LoginScreen} from './screens/login/login.screen';
import {InputComponent} from './components/input/input.component';
import {ViewProductComponent} from './screens/view-product/view-product.component';
import {CartScreen} from './screens/cart/cart.screen';
import {CardCartProductComponent} from './components/card-cart-product/card-cart-product.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {ComputerScreen} from './screens/computer/computer.screen';
import {SalesScreen} from './screens/sales/sales.screen';


const appRoutes: Routes = [
  {path: '', component: HomeScreen},
  {path: 'contact', component: ContactScreen},
  {path: 'about', component: AboutScreen},
  {path: 'products', component: ProductsScreen},
  {path: 'login', component: LoginScreen},
  {path: 'cart', component: CartScreen},
  {path: 'computerforeveryone', component: ComputerScreen},
  {path: 'sales', component: SalesScreen},
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    AboutScreen,
    CardAboutComponent,
    HomeScreen,
    IconComponent,
    ContactScreen,
    FooterComponent,
    CardProductComponent,
    ProductsScreen,
    LoginScreen,
    InputComponent,
    ViewProductComponent,
    CartScreen,
    CardCartProductComponent,
    ComputerScreen,
    SalesScreen

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: true}
    ),
    FormsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
