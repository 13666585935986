import {Component, Input, OnInit} from '@angular/core';
import Product from '../../models/Product';

@Component({
  selector: 'ProductsScreen',
  templateUrl: './products.screen.html',
  styleUrls: ['./products.screen.scss']
})
export class ProductsScreen implements OnInit {

  public value
  public getProducts() {
    return [
    //  new Product('Product 1', 'Product 1', 1000.00, 'Assim mesmo, a percepção das dificuldades ainda não demonstrou convincentemente', 'Assim mesmo, a percepção das dificuldades ainda não demonstrou convincentemente','../assets/imgs/product.jpg',1),
   //   new Product('Product 2', 'Product 2', 4000.00, 'Assim mesmo, a percepção das dificuldades ainda não demonstrou convincentemente', 'Assim mesmo, a percepção das dificuldades ainda não demonstrou convincentemente','../assets/imgs/product.jpg',2),
    //  new Product('Product 3', 'Product 3', 5000.00, 'Assim mesmo, a percepção das dificuldades ainda não demonstrou convincentemente', 'Assim mesmo, a percepção das dificuldades ainda não demonstrou convincentemente','../assets/imgs/product.jpg',2),
    ];
  }

  private obj;

  constructor() {
  }

  change(event) {
    console.log(this.value)

  }

  ngOnInit() {
  }

}
